import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ShopifyBuyButton from "./ShopifyButton";
import { Helmet } from "react-helmet";
import { Contact } from "./contact";
import axios from "axios";

export const ConsultationPage = (props) => {
  const [opacity, setOpacity] = useState(1);
  const location = useLocation();
  const currentPrice = 13750;
  const [showSubmissionPopup, setShowSubmissionPopup] = useState(false);

  // const [primaryService, setPrimaryService] = useState("consultancy");

  // Function to update primaryService state
  // const handlePrimaryServiceChange = (e) => {
  //   setPrimaryService(e.target.value);
  //   // Reset the secondary option when the primary changes
  //   setFormData((prevState) => ({ ...prevState, service: "" }));
  // };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    question: "",
    option: "",
  });

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0); // Adjust the denominator to control the fade speed
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const scrollToElement = () => {
      const hash = location.hash.replace("#", "");
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    window.scrollTo(0, 0);
    scrollToElement(); // Call the function to scroll to the element

    window.addEventListener("hashchange", scrollToElement, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("hashchange", scrollToElement);
    };
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitData = {
      data: formData, // Ensure formData contains all the fields with correct names
    };
    console.log("Submitting Data:", submitData);
    //  "http://localhost:1337/api/surveys"
    try {
      const response = await axios.post(
        "https://somarasa-back.onrender.com/api/surveys",
        submitData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Success:", response.data);
      setShowSubmissionPopup(true);
      setFormData({
        name: "",
        email: "",
        mobile: "",
        address: "",
        question: "",
        option: "",
      });
    } catch (error) {
      console.error("Error:", error.response || error.message);
    }
  };

  const submitData = {
    data: {
      name: "Test Name",
      email: "test@email.com",
      mobile: "1234567890",
      address: "Test Address",
      question: "Test Question",
      consultation: "Test Consultation",
    },
  };

  const [openedDropdown, setOpenedDropdown] = useState(null);

  const toggleDropdown = (index) => {
    if (openedDropdown === index) {
      setOpenedDropdown(null); // Close dropdown
    } else {
      setOpenedDropdown(index); // Open dropdown
    }
  };

  const headerData = props.data?.header;
  const servicesData = props.data?.services;
  const contactInfo = props.data?.Contact;
  const membership = props.data?.membership;
  const description = props.data?.description;
  const faqs = props.data?.faqs;

  // const originalPrice = parseFloat(props.data?.services.originalPrice);
  // const currentPrice = parseFloat(props.data?.services.currentPrice);
  // const discountPercentage = Math.round(
  //   ((originalPrice - currentPrice) / originalPrice) * 100
  // );

  const SubmissionPopup = ({ show, onClose }) => {
    if (!show) return null;

    return (
      <div className="submission-popup">
        <div className="popup-content">
          <p>Your Responce has been submitted. We will get back to you soon.</p>
          <h2>Submission Successful!</h2>
          <button className="btn btn-custom" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  };

  return (
    <div id="consultation-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Somarasa Consultation Services | Spiritual Guidance & Expert Advice
        </title>
        <meta
          name="title"
          content="Somarasa Consultation Services | Spiritual Guidance & Expert Advice"
        />
        <meta
          name="description"
          content="Explore Somarasa's personalized consultation services offering spiritual guidance, astrology, meditation, and Hindu rituals. Enhance your spiritual journey with expert advice tailored to your needs."
        />
        <meta
          name="keywords"
          content="Somarasa consultation, spiritual guidance, personalized astrology, meditation advice, Hindu rituals, spiritual journey, expert consultations, astrology services, spiritual support, Hindu spirituality, life coaching, spiritual alignment, Kundali reading, Dharma, Artha, Kama, Moksha, Somarasa Discoveries, Somarasa spiritual services, Somarasa online consultation, Spiritual guidance services, Hindu rituals consultation, Kundali astrology readings, Meditation guidance services, Personalized astrological readings, Astrology and spirituality services, Vedic astrology readings online, Hindu spiritual guidance, Jyotisha Acharyas astrology, spiritual growth and wellbeing, birth chart reading online, Kundali services in India, Indian spiritual practices online, spiritual consultations for life guidance, meditation practices and guidance, traditional Hindu rituals support, comprehensive Kundali astrology by Jyotisha Acharyas, book a spiritual consultation online for personalized guidance, affordable Kundali and astrological readings in India, personalized Kundali readings from Kashi astrologers, where to get spiritual guidance for Dharma and Artha, how to get personalized Hindu spiritual rituals, best place for spiritual growth and Kundali readings, how to get a complete Kundali analysis and guidance, Dharma Artha Kama Moksha consultations for personal growth, spiritual guidance for life's challenges through astrology"
        />
        <meta name="robots" content="index, follow" />

        <meta name="author" content="Somarasa Discoveries Private Limited" />
        <link rel="canonical" href="https://somarasa.co.in/consultation" />
        <meta name="language" content="English" />
        <meta name="geo.region" content="IN-MP" />
        <meta name="geo.placename" content="India" />
        <meta name="geo.position" content="20.5937;78.9629" />
        <meta name="ICBM" content="20.5937, 78.9629" />

        <meta
          property="og:title"
          content="Somarasa Consultation Services | Spiritual Guidance & Expert Advice"
        />
        <meta
          property="og:description"
          content="Connect with Somarasa for personalized spiritual consultations and expert advice on astrology, meditation, and Hindu rituals. Tailored guidance to enhance your spiritual journey."
        />
        <meta property="og:url" content="https://somarasa.co.in/consultation" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="./somarasa full logo red background.webp"
        />
        <meta property="og:site_name" content="Somarasa Discoveries" />
        <meta property="og:locale" content="en_IN" />
        <meta property="og:locale:alternate" content="hi_IN" />
        <meta property="article:section" content="Spiritual Consultation" />
        <meta
          property="article:tag"
          content="Spiritual guidance, Kundali astrology, Hindu rituals, Meditation guidance, Dharma, Artha, Jyotisha Acharyas"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Somarasa Consultation Services | Spiritual Guidance & Expert Advice"
        />
        <meta
          name="twitter:description"
          content="Discover personalized spiritual guidance and expert advice at Somarasa. Tailored consultations in astrology, meditation, and Hindu rituals for a fulfilling spiritual journey."
        />
        <meta
          name="twitter:image"
          content="./somarasa full logo red background.webp"
        />
      </Helmet>
      <header id="header" style={{ opacity }}>
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Somarasa Services</title>
                <link
                  rel="canonical"
                  href="http://localhost:3000/consultation"
                />
              </Helmet>
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {headerData ? headerData.title : "Loading"}
                    <span></span>
                  </h1>
                  <p style={{ marginBottom: "60px" }}>
                    {headerData ? headerData.paragraph2 : "Loading"}
                  </p>
                  <a
                    href="#consultancy"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Explore
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <SubmissionPopup
        show={showSubmissionPopup}
        onClose={() => setShowSubmissionPopup(false)}
      />
      <div id="consultancy" className="text-center">
        <div className="container">
          <div>
            <h2>Explore The Four Pillars</h2>
            <h3>Let the Nakshatras Guide You</h3>

            <div className="navigation-boxes">
              <a href="#dharma" className="navigation-box dharma">
                Dharma
              </a>
              <a href="#artha" className="navigation-box artha">
                Artha
              </a>
              <a href="#kama" className="navigation-box kama">
                Kama
              </a>
              <a href="#moksha" className="navigation-box moksha">
                Moksha
              </a>
            </div>

            <div id="dharma" className="services" style={{ padding: "0" }}>
              <div id="consultation-page">
                <div className="container">
                  <div className="section-title text-center">
                    <h2>Dharma</h2>
                  </div>
                  <div className="section sampoorna-kundali-section">
                    <div className="align-items-center">
                      <h2>Sampoorna Kundali Query</h2>

                      <div className="col-md-6" style={{ padding: "0" }}>
                        <img
                          src={require("./sampoorna kundali.webp")}
                          alt="Sampoorna Kundali Illustration"
                          className="illustration-image"
                        />
                      </div>
                      <div className="col-md-6 text-block">
                        <h3>Unveil the Secrets of Your Cosmic Blueprint</h3>
                        <p>
                          The Sampoorna Kundali is a comprehensive astrological
                          analysis that delves deep into every aspect of your
                          life. Crafted by the revered Jyotisha Acharyas of
                          Kashi, this Kundali is more than just a birth chart;
                          it’s a complete roadmap of your cosmic journey,
                          designed to provide profound insights into your past,
                          present, and future. Whether you’re looking to
                          understand your true purpose or navigate the
                          complexities of life, the Sampoorna Kundali offers a
                          timeless guide to your spiritual and worldly path.
                        </p>
                      </div>
                      <ul style={{ color: "#807171" }}>
                        Disclaimer: The actual product may vary slightly in
                        color, design, or appearance from the image shown due to
                        lighting, manufacturing variations, or other factors
                      </ul>
                      <div>
                        <h4>What Will You Get in This?</h4>
                        <ul className="styled-list">
                          <li>
                            A Complete Kundali in booklet format, meticulously
                            created by the Acharyas of Kashi, offering detailed
                            insights into every aspect of your astrological
                            chart.
                          </li>
                          <li>
                            A Customized Spiritual Kit tailored specifically for
                            you, based on your Kundali. This kit will contain
                            items and tools to help you grow spiritually and
                            attract abundance in your life.
                          </li>
                          <li>
                            A One-on-One Call with the Acharya to help you fully
                            understand your Kundali. During this personalized
                            consultation, you’ll gain deeper knowledge about
                            your chart and how to align with cosmic forces
                            guiding you.
                          </li>
                        </ul>
                        <p
                          className="highlight"
                          style={{ textAlign: "center", paddingTop: "0px" }}
                        >
                          To Enquire about the price and the process Book Now
                          and get a call from our team!
                        </p>
                        <div className="price-section">
                          <span className="current-price">₹0.00</span>
                          <span className="original-price">Regular price</span>
                          <span className="discount">Free Enquiry</span>
                        </div>
                        {/* <div className="tax-info">
                          Taxes included. Shipping calculated at checkout.
                        </div> */}
                        <p style={{ textAlign: "center", paddingBottom: "0" }}>
                          Delivery Time: 1 month
                        </p>
                        <div className="shopify-btn">
                          <ShopifyBuyButton productId="9641700393276" />
                        </div>{" "}
                      </div>
                    </div>
                  </div>

                  {/* Sampoorna Kundali Section */}
                  <div className="section sampoorna-kundali-section">
                    <div className="align-items-center">
                      <h2>Saptvargi Kundali</h2>
                      <div className="col-md-6" style={{ padding: "0" }}>
                        <img
                          src={require("./Janm patrika.webp")}
                          alt="Sampoorna Kundali Illustration"
                          className="illustration-image"
                        />
                      </div>
                      <div className="col-md-6 text-block">
                        <h3>Unveil the Secrets of Your Cosmic Blueprint</h3>
                        <p>
                          The Saptvargi Kundali is a comprehensive astrological
                          analysis that delves deep into every aspect of your
                          life. Crafted by the revered Jyotisha Acharyas of
                          Kashi, this Kundali is more than just a birth chart;
                          it’s a complete roadmap of your cosmic journey,
                          designed to provide profound insights into your past,
                          present, and future. Whether you’re looking to
                          understand your true purpose or navigate the
                          complexities of life, the Saptvargi Kundali offers a
                          timeless guide to your spiritual and worldly path.
                        </p>
                      </div>
                      <ul style={{ color: "#807171" }}>
                        Disclaimer: The actual product may vary slightly in
                        color, design, or appearance from the image shown due to
                        lighting, manufacturing variations, or other factors
                      </ul>
                      <div>
                        <h4>What Will You Get in This?</h4>
                        <ul className="styled-list">
                          <li>
                            A Complete Kundali in booklet format, meticulously
                            created by the Acharyas of Kashi, offering detailed
                            insights into every aspect of your astrological
                            chart.
                          </li>
                          <li>
                            A Customized Spiritual Kit tailored specifically for
                            you, based on your Kundali. This kit will contain
                            items and tools to help you grow spiritually and
                            attract abundance in your life.
                          </li>
                          <li>
                            A One-on-One Call with the Acharya to help you fully
                            understand your Kundali. During this personalized
                            consultation, you’ll gain deeper knowledge about
                            your chart and how to align with cosmic forces
                            guiding you.
                          </li>
                        </ul>
                        <div className="price-section">
                          <span className="current-price">₹5,500</span>
                          <span className="original-price">MRP ₹8,500</span>
                          <span className="discount">(35% OFF)</span>
                        </div>
                        <div className="tax-info">exclusive of all taxes</div>
                        <p style={{ textAlign: "center", paddingBottom: "0" }}>
                          Delivery Time: 1 month
                        </p>
                        <div className="shopify-btn">
                          <ShopifyBuyButton productId="9641700262204" />
                        </div>{" "}
                      </div>
                    </div>
                  </div>

                  {/* Spiritual Guidance Section */}
                  <div className="section spiritual-guidance-section">
                    <div className="row align-items-center">
                      <h2>Spiritual Guidance</h2>
                      <div className="col-md-6" style={{ padding: "0" }}>
                        <img
                          src={require("./Sadhu bg removed.webp")}
                          alt="Spiritual Guidance Illustration"
                          className="illustration-image"
                        />
                      </div>
                      <div className="col-md-6 text-block">
                        <div></div>
                        <h3>
                          Transform Your Life with Personalized Spiritual
                          Support
                        </h3>
                        <p>
                          Our Spiritual Guidance service is a transformative
                          offering designed to provide personalized support on
                          your spiritual journey. Whether you’re seeking
                          clarity, inner peace, or deeper self-awareness, this
                          service offers one-on-one mentorship to help you align
                          with your higher self and navigate life’s challenges.
                          Perfect for individuals who wish to enhance their
                          spiritual practice, find balance, and cultivate
                          mindfulness in their daily lives.
                        </p>
                      </div>
                      <div>
                        <h4>What Will You Get in This?</h4>
                        <ul className="styled-list">
                          <li>
                            Personalized Spiritual Support tailored to your
                            unique path and needs, focusing on helping you
                            cultivate inner peace, mindfulness, and spiritual
                            growth.
                          </li>
                          <li>
                            One-on-One Mentorship with our experienced mentor,
                            providing direct guidance and support to help you
                            overcome life's obstacles and connect with your true
                            purpose.
                          </li>
                          <li>
                            Tools for Spiritual Growth, including practical
                            exercises, meditative practices, and personalized
                            insights to enhance your journey toward
                            enlightenment and inner peace.
                          </li>
                        </ul>
                        <div className="price-section">
                          <span className="current-price">
                            Starting: ₹1,000
                          </span>
                          <span className="original-price">MRP ₹1,800</span>
                          <span className="discount">(44% OFF)</span>
                        </div>
                        <div className="tax-info">exclusive of all taxes</div>
                        <ul style={{ color: "#807171" }}>
                          Please select a different time range and add it to
                          your cart to check the price for each duration. The
                          price will be displayed in your cart after clicking
                          'Buy Now'.
                        </ul>
                        <div className="shopify-btn">
                          <ShopifyBuyButton productId="9641700032828" />
                        </div>{" "}
                      </div>
                    </div>
                
                  </div>
                <h5 style={{ paddingTop: "10px", color: "white" }}>
                  Enquire Now for Any Doubts! Click the button below to open the
                  form and get your queries answered by our team.
                </h5>
                <a
                  href="#prebooking"
                  className="btn btn-custom"
                  style={{ margin: "0" }}
                >
                  Enquire Now!
                </a>
                </div>
              </div>
            </div>
          </div>
          <div id="artha" className="container">
            <div className="section-title text-center">
              <h2>Artha</h2>
            </div>
            <p className="styled-paragraph">
              Our Artha consultation services focus on helping you achieve
              financial stability and growth. We offer personalized financial
              planning, investment strategies, and wealth management advice to
              secure your financial future. Whether you are looking to build a
              robust portfolio, plan for major life events, or manage your
              assets effectively, our experts are here to guide you every step
              of the way.
            </p>
            <a href="#prebooking" className="btn btn-custom">
              Enquire Now!
            </a>
          </div>
          <div id="kama" className="container">
            <div className="section-title">
              <h2>Kama</h2>
            </div>
            <p className="styled-paragraph">
              Kama consultations are designed to enhance your personal and
              intimate life. Our experienced counselors provide guidance on
              personal well-being, relationship dynamics, and sexual health. We
              address concerns with sensitivity and confidentiality, offering
              practical advice and support to help you achieve a fulfilling and
              balanced personal life. Explore ways to improve your
              relationships, boost your self-esteem, and lead a more satisfying
              life.
            </p>
            <a href="#prebooking" className="btn btn-custom">
              Enquire Now!
            </a>
          </div>
          <div id="moksha" className="container">
            <div className="section-title text-center">
              <h2>Moksha</h2>
            </div>
            <p className="styled-paragraph">
              Our Moksha consultation services aim to prepare you for a peaceful
              and fulfilling retirement. We offer comprehensive retirement
              planning, spiritual guidance, and strategies for achieving a
              balanced and content life post-retirement. Whether you are seeking
              financial security, emotional well-being, or spiritual growth, our
              experts provide the insights and tools to help you navigate this
              important life stage with confidence and purpose.
            </p>
            <a href="#prebooking" className="btn btn-custom">
              Enquire Now!
            </a>
          </div>

          <div id="prebooking" className="form section-title">
            <div className="container">
              {/* <h2 className="text-center">Artha</h2> */}
              <p style={{ fontWeight: "bold", fontSize: "22px" }}>
                Use this form to tell your query
              </p>
              <form className="consultation-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    id="mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    placeholder="Mobile"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Address"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <select
                    id="option"
                    name="option"
                    value={formData.option}
                    onChange={handleChange}
                    className="form-control"
                    required
                  >
                    <option value="" disabled>
                      Select an Option
                    </option>
                    <option value="Dharma">Dharma</option>
                    <option value="Artha">Artha</option>
                    <option value="Kama">Kama</option>
                    <option value="Moksha">Moksha</option>
                  </select>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    id="question"
                    name="question"
                    value={formData.question}
                    onChange={handleChange}
                    placeholder="Question"
                    className="form-control"
                    required
                  />
                </div>

                <button type="submit" className="btn btn-custom">
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div id="donation" className="faqs">
            <div id="donationImpact" className="container">
              <h2>Frequently Asked Questions</h2>
              <ul>
                {faqs ? (
                  faqs.map((item, index) => (
                    <li key={index} onClick={() => toggleDropdown(index)}>
                      {item.title}
                      {openedDropdown === index && (
                        <div className="dropdown-content">{item.details}</div>
                      )}
                    </li>
                  ))
                ) : (
                  <p>Loading faqs data...</p>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="contact">
        <Contact data={contactInfo} />
      </div>
    </div>
  );
};
export default ConsultationPage;

// "Consultancy": [
//   {
//     "id": "birth",
//     "img": "img/icons/output 16.webm",
//     "name": "Birth Chart Reading",
//     "currentPrice": "Director"
//   },
//   {
//     "id": "doshas",
//     "img": "img/icons/output 18.webm",
//     "name": "Doshas & Solutions",
//     "currentPrice": "Senior Designer"
//   },
//   {
//     "id": "guidance",
//     "img": "img/icons/output 17.webm",
//     "name": "Spiritual Guidance",
//     "currentPrice": "Senior Designer"
//   },
//   {
//     "id": "one-page",
//     "img": "img/icons/output 21.webm",
//     "name": "One Page Kundali"
//   },
//   {
//     "id": "Saptvargi",
//     "img": "img/icons/output 22.webm",
//     "name": "Saptvargi Kundali"
//   },
//   {
//     "id": "Sampoorna",
//     "img": "img/icons/output 19.webm",
//     "name": "Sampoorna Kundali"
//   }
// ],

{
  /* <div>
  <div className="row">
    {servicesData
      ? servicesData.map((service, i) => {
          // Ensure the prices are numbers
          const originalPrice = parseFloat(service.originalPrice);
          const currentPrice = parseFloat(service.currentPrice);
          // Calculate discount percentage
          const discountPercentage = Math.round(
            ((originalPrice - currentPrice) / originalPrice) * 100
          );

          return (
            <div
              key={`${service.name}-${i}`}
              id={service.id}
              className="col-md-4 col-sm-12"
            >
              <div className="thumbnail">
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="consultancy-img"
                >
                  <source
                    src={service.img}
                    alt={service.name}
                    type="video/webm"
                  />
                  Your browser does not support the video tag.
                </video>
                <div className="caption">
                  <h4>{service.name}</h4>
                  <p
                    style={{
                      paddingBottom: "0",
                      textAlign: "center",
                    }}
                  >
                    {service.descriptionQ}
                  </p>
                  <p>{service.description}</p>
                  <div className="price-section">
                    <span className="current-price">₹{currentPrice}</span>
                    <span className="original-price">MRP ₹{originalPrice}</span>
                    <span className="discount">
                      ({discountPercentage}% OFF)
                    </span>
                  </div>
                  <div className="tax-info">exclusive of all taxes</div>
                  <div className="shopify-btn">
                    <ShopifyBuyButton productId={service.productId} />
                  </div>{" "}
                </div>
                { <button type="submit" className="btn btn-custom">
                              Book Now
                            </button> }
              </div>
            </div>
          );
        })
      : "Loading services..."}
  </div>
</div>; */
}
