import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Explore = (props) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = React.useState(false);

  const handleNavigate = (id, path) => {
    if (id === "services") {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    }else if (id === "shop") {
      // Open the link in a new tab
      window.open("https://thesomastore.com/", "_blank");
    } else {
      navigate(id);
    }
  };

  const SubmissionPopup = ({ show, onClose }) => {
    if (!show) return null;

    return (
      <div className="submission-popup">
        <div className="popup-content">
          <h2>Coming soon!</h2>
          <button className="btn btn-custom" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  };

  return (
    <div id="explore" className="text-center">
      <Helmet>
        <meta
          name="description"
          content="Explore the unique blend of services at Somarasa, where we bring the essence of global traditions into the modern world. Discover how ancient wisdom and practices can inspire and transform your present."
        />
        <meta
          name="keywords"
          content="Somarasa, global traditions, cultural heritage, ancient wisdom, cultural transformation, spiritual practices, modern world"
        />
        <meta
          property="og:description"
          content="Discover the essence of global traditions and how Somarasa integrates ancient wisdom into modern practices."
        />
        <meta
          property="og:image"
          content="./somarasa full logo red background.webp"
        />
        <meta property="og:url" content="https://somarasa.co.in/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Explore Somarasa's services that connect you with global traditions and ancient wisdom."
        />
        <meta
          name="twitter:image"
          content="./somarasa full logo red background.webp"
        />
      </Helmet>

      <div className="container">
        <div className="section-title">
          <h2>What we do...</h2>
        </div>
        <div className="styled-paragraph">
          <p>
            At Somarasa, we are dedicated to bringing the essence of global
            traditions into the modern world. Our work is rooted in the belief
            that ancient wisdom and practices are not just relics of the past,
            but vital sources of inspiration and transformation for the present.
          </p>
          <p>
            We do this by offering a unique blend of services that allow you to
            connect with the rich cultural heritage of the world:
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  onClick={() => handleNavigate(d.id)}
                  className="col-md-4"
                  style={{ padding: "30px 50px 10px", cursor: "pointer" }}
                >
                  <div className="explore-image">
                    <img
                      src={d.img}
                      alt={d.title}
                    />
                  </div>
                  <h3
                    className="gradient-title"
                    onClick={() => handleNavigate(d.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {d.title}
                  </h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
      <SubmissionPopup show={showPopup} onClose={() => setShowPopup(false)} />
    </div>
  );
};
